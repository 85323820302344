import Contacts from "./Contacts";
import ProjectList from "./ProjectList";
import Services from "./Services";

export const pages = {
  projects: {
    logo: true,
    BottomComponent: ProjectList,
  },
  about: {
    title: "ABOUT",
    mobileTopDescription: true,
    description:
      "SENSAI IS A TEAM OF CREATIVES AND VISUAL STORYTELLERS. WE WORK ACROSS FASHION, DESIGN, LIFESTYLE, AND BEYOND, APPROACHING EVERY PROJECT AS A WAY TO BRING BRANDS TO LIFE THROUGH EMOTIONALLY ENGAGING VISUALS. OUR EXPERTISE IN CREATIVE DIRECTION, GRAPHIC DESIGN, VIDEO, AND PHOTOGRAPHY IS COMBINED WITH A STRATEGIC VISION, ENSURING EVERY PROJECT RESONATES DEEPLY WITH ITS INTENDED AUDIENCE. EACH PROJECT BECOMES AN OPPORTUNITY TO TRANSLATE THE ESSENCE OF A BRAND INTO A COMPELLING VISUAL NARRATIVE, ENSURING LASTING IMPACT AND CONNECTION.",
  },
  contacts: {
    title: "CONTACTS",
    BottomComponent: Contacts,
    desktopMiddleBottomComponent: true,
  },
  services: {
    title: "SERVICES",
    BottomComponent: Services,
    desktopMiddleBottomComponent: true,
  },
};
