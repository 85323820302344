import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import H1 from "./Ui/H1";
import Media from "./Styles/Media";
import Nav from "./Nav";
import Text from "./Ui/Text";
import Flex from "./Ui/Flex";

const MainContent = ({ page, path, clear }) => {
  const {
    logo,
    title,
    description,
    BottomComponent,
    mobileTopDescription,
    desktopMiddleBottomComponent,
  } = page;
  return (
    <Container>
      <Top>
        <Mobile>
          {mobileTopDescription && <Text bold>{description}</Text>}
        </Mobile>
      </Top>
      <Middle>
        {logo ? <Logo /> : title ? <H1>{title}</H1> : null}
        <Mobile>
          <Nav path={path} clear={clear} />
        </Mobile>
        {description && (
          <Desktop>
            <Flex center custom="max-width: 530px; margin: 0 auto;">
              <Text custom="text-align: justify;" bold>
                {description}
              </Text>
            </Flex>
          </Desktop>
        )}
        {desktopMiddleBottomComponent && (
          <Desktop>
            <BottomComponent desktop />
          </Desktop>
        )}
      </Middle>
      <Bottom>
        {BottomComponent ? (
          desktopMiddleBottomComponent ? (
            <Mobile>
              <BottomComponent />
            </Mobile>
          ) : (
            <BottomComponent />
          )
        ) : null}
      </Bottom>
    </Container>
  );
};

const Mobile = styled.div`
  width: 100%;
  ${Media.tablet`
    display: none;
  `}
`;
export const Desktop = styled.div`
  display: none;
  ${Media.tablet`
    display: block;
    width: 100%;
  `}
`;
export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: grid;
  grid-template-rows: 1fr auto 1fr; /* Le righe hanno diverse dimensioni */
`;

export const Top = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px 15px;
`;

const Middle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 15px;
  z-index: 1;
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  padding: 23px 15px;
`;

export default MainContent;
