export const projects = [
  {
    url: "/projects/yoox-net-a-porter_fw23-24_campaign",
    image: "/projects/yoox-net-a-porter_fw23-24_campaign/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_fw23-24_campaign/_cover.jpg",
    name: "YOOX Net-A-Porter FW23-24 Campaign",
    field: "Fashion",
    services: "Art Direction & Film Direction",
    images: [
      "/projects/yoox-net-a-porter_fw23-24_campaign/2.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/3.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/4.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/5.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/6.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/7.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/8.jpg",
      "/projects/yoox-net-a-porter_fw23-24_campaign/9.jpg",
      "https://player.vimeo.com/video/1007834802?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479___https://player.vimeo.com/video/1007602812?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
    credits: "Photography by Hugo Lecrux",
  },
  {
    url: "/projects/8_by_yoox_fw24-25_campaign",
    image: "/projects/8_by_yoox_fw24-25_campaign/_cover_mobile.jpg",
    desktop: "/projects/8_by_yoox_fw24-25_campaign/_cover.jpg",
    name: "8 by Yoox FW23-24 Campaign",
    field: "Fashion",
    services: "Art Direction & Film Direction",
    images: [
      "/projects/8_by_yoox_fw24-25_campaign/2.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/3.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/4.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/5.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/6.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/7.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/8.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/9.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/10.jpg",
      "/projects/8_by_yoox_fw24-25_campaign/11.jpg",
      "https://player.vimeo.com/video/1007838029?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479___https://player.vimeo.com/video/1007597023?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
    credits: "Photography by Teresa Ciocia",
  },
  {
    url: "/projects/yoox-net-a-porter_ss23_editorial",
    image: "/projects/yoox-net-a-porter_ss23_editorial/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_ss23_editorial/_cover.jpg",
    name: "YOOX Net-A-Porter SS23 Editorial",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/yoox-net-a-porter_ss23_editorial/1.jpg",
      "/projects/yoox-net-a-porter_ss23_editorial/2.jpg",
      "/projects/yoox-net-a-porter_ss23_editorial/3.jpg",
      "/projects/yoox-net-a-porter_ss23_editorial/4.jpg",
      "/projects/yoox-net-a-porter_ss23_editorial/5.jpg",
    ],
    credits: "Photography by Jorge Fuster",
  },
  {
    url: "/projects/liu_jo_fw22-23_editorial",
    image: "/projects/liu_jo_fw22-23_editorial/_cover_mobile.jpg",
    desktop: "/projects/liu_jo_fw22-23_editorial/_cover.jpg",
    name: "Liu Jo FW21-22 Editorial",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/liu_jo_fw22-23_editorial/1.jpg",
      "/projects/liu_jo_fw22-23_editorial/2.jpg",
      "/projects/liu_jo_fw22-23_editorial/3.jpg",
      "/projects/liu_jo_fw22-23_editorial/4.jpg",
      "/projects/liu_jo_fw22-23_editorial/5.jpg",
      "/projects/liu_jo_fw22-23_editorial/6.jpg",
      "/projects/liu_jo_fw22-23_editorial/7.jpg",
      "/projects/liu_jo_fw22-23_editorial/8.jpg",
    ],
    credits: "Photography by Raffaele Cerulo <br> Creative collaboration with Francesco Roncaglia",
  },
  {
    url: "/projects/hemeryne",
    image: "/projects/hemeryne/_cover_mobile.jpg",
    desktop: "/projects/hemeryne/_cover.jpg",
    name: "Hemeryne",
    field: "Beauty",
    services: "Identity",
    images: [
      "/projects/hemeryne/1.jpg",
      "/projects/hemeryne/2.jpg",
      "/projects/hemeryne/3.jpg",
      "/projects/hemeryne/4.jpg",
      "/projects/hemeryne/5.jpg",
      "/projects/hemeryne/6.jpg",
    ],
  },
  {
    url: "/projects/liu_jo_denim",
    image: "/projects/liu_jo_denim/_cover_mobile.jpg",
    desktop: "/projects/liu_jo_denim/_cover.jpg",
    name: "Liu Jo Denim",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/liu_jo_denim/1.jpg",
      "/projects/liu_jo_denim/2.jpg",
      "/projects/liu_jo_denim/3.jpg",
      "/projects/liu_jo_denim/4.jpg",
      "/projects/liu_jo_denim/5.jpg",
      "/projects/liu_jo_denim/6.jpg",
    ],
    credits: "Photography by Matteo Montanari <br> Creative collaboration with Francesco Roncaglia",
  },

  {
    url: "/projects/yoox-net-a-porter_home_decor",
    image: "/projects/yoox-net-a-porter_home_decor/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_home_decor/_cover.jpg",
    name: "YOOX Net-A-Porter Home Decor Editorial",
    field: "Design",
    services: "Film Direction",
    images: [
      "https://player.vimeo.com/video/1007594627?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      "https://player.vimeo.com/video/1007590971?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      "https://player.vimeo.com/video/1007590863?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      "https://player.vimeo.com/video/1007590656?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
  },
  {
    url: "/projects/doweedo",
    image: "/projects/doweedo/_cover_mobile.jpg",
    desktop: "/projects/doweedo/_cover.jpg",
    name: "Doweedo",
    field: "Lifestyle",
    services: "Identity",
    images: [
      "/projects/doweedo/1.jpg",
      "/projects/doweedo/2.jpg",
      "/projects/doweedo/3.jpg",
      "/projects/doweedo/4.jpg",
      "/projects/doweedo/5.jpg",
    ],
  },
  {
    url: "/projects/liu_jo_fragrance",
    image: "/projects/liu_jo_fragrance/_cover_mobile.jpg",
    desktop: "/projects/liu_jo_fragrance/_cover.jpg",
    name: "Liu Jo Fragrance Campaign",
    field: "Fashion",
    services: "Creative Direction",
    images: [
      "/projects/liu_jo_fragrance/2.jpg",
      "/projects/liu_jo_fragrance/3.jpg",
      "/projects/liu_jo_fragrance/4.jpg",
      "/projects/liu_jo_fragrance/6.jpg",
      "/projects/liu_jo_fragrance/7.jpg",
      "https://player.vimeo.com/video/1008359429?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      "https://player.vimeo.com/video/671662033?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      "https://player.vimeo.com/video/671695150?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
    credits: "Photography by Jacopo Noera <br> Film Direction by Popkorn films",
  },
  {
    url: "/projects/yoox-net-a-porter_superstar",
    image: "/projects/yoox-net-a-porter_superstar/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_superstar/_cover.jpg",
    name: "YOOX Net-A-Porter Superstar",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/yoox-net-a-porter_superstar/1.jpg",
      "/projects/yoox-net-a-porter_superstar/2.jpg",
      "/projects/yoox-net-a-porter_superstar/3.jpg",
      "/projects/yoox-net-a-porter_superstar/4.jpg",
      "/projects/yoox-net-a-porter_superstar/5.jpg",
      "/projects/yoox-net-a-porter_superstar/6.jpg",
      "/projects/yoox-net-a-porter_superstar/7.jpg",
    ],
    credits: "Illustration by Sara Andreasson",
  },
  {
    url: "/projects/yoox-net-a-porter_x_chris_glass",
    image: "/projects/yoox-net-a-porter_x_chris_glass/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_x_chris_glass/_cover.jpg",
    name: "YOOX Net-A-Porter x Chris Glass",
    field: "Design",
    services: "Art Direction & Film Direction",
    images: [
      "/projects/yoox-net-a-porter_x_chris_glass/1.jpg",
      "/projects/yoox-net-a-porter_x_chris_glass/2.jpg",
      "https://player.vimeo.com/video/1007603399?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
    credits: "Photography by Alessio Keilty",
  },
  {
    url: "/projects/sowe",
    image: "/projects/sowe/_cover_mobile.jpg",
    desktop: "/projects/sowe/_cover.jpg",
    name: "Sowe",
    field: "Fashion",
    services: "Identity & Product Design",
    images: [
      "/projects/sowe/1.jpg",
      "/projects/sowe/2.jpg",
      "/projects/sowe/3.jpg",
      "/projects/sowe/4.jpg",
      "/projects/sowe/4-1.jpg",
      "/projects/sowe/5.jpg",
      "/projects/sowe/6.jpg",
      "/projects/sowe/7.gif",
    ],
  },
  {
    url: "/projects/yoox-net-a-porter_editorial",
    image: "/projects/yoox-net-a-porter_editorial/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_editorial/_cover.jpg",
    name: "YOOX Net-A-Porter Editorial X Hi Guyz",
    field: "Fashion",
    services: "Art Direction & Film Direction",
    images: [
      "/projects/yoox-net-a-porter_editorial/2.jpg",
      "/projects/yoox-net-a-porter_editorial/3.jpg",
      "/projects/yoox-net-a-porter_editorial/4.jpg",
      "/projects/yoox-net-a-porter_editorial/5.jpg",
      "https://player.vimeo.com/video/1007840631?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
  },
  {
    url: "/projects/yoox-net-a-porter_fw23-24_editorial",
    image: "/projects/yoox-net-a-porter_fw23-24_editorial/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_fw23-24_editorial/_cover.jpg",
    name: "YOOX Net-A-Porter FW23-24 Editorial",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/yoox-net-a-porter_fw23-24_editorial/1.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/2.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/3.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/4.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/5.jpg",
      "/projects/yoox-net-a-porter_fw23-24_editorial/6.jpg",
    ],
    credits: "Photography by Alessio Keilty",
  },
  {
    url: "/projects/liu_jo_ss21_editorial",
    image: "/projects/liu_jo_ss21_editorial/_cover_mobile.jpg",
    desktop: "/projects/liu_jo_ss21_editorial/_cover.jpg",
    name: "Liu Jo SS21 Editorial",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/liu_jo_ss21_editorial/1.jpg",
      "/projects/liu_jo_ss21_editorial/2.jpg",
      "/projects/liu_jo_ss21_editorial/3.jpg",
      "/projects/liu_jo_ss21_editorial/4.jpg",
      "/projects/liu_jo_ss21_editorial/5.jpg",
      "/projects/liu_jo_ss21_editorial/6.jpg",
      "/projects/liu_jo_ss21_editorial/7.jpg",
      "/projects/liu_jo_ss21_editorial/8.jpg",
      "/projects/liu_jo_ss21_editorial/9.jpg",
      "/projects/liu_jo_ss21_editorial/10.jpg",
    ],
    credits: "Photography by Alvaro Beamud Cortés <br> Creative collaboration with Francesco Roncaglia",
  },
  {
    url: "/projects/yoox-net-a-porter_fall_24_campaign",
    image: "/projects/yoox-net-a-porter_fall_24_campaign/_cover_mobile.jpg",
    desktop: "/projects/yoox-net-a-porter_fall_24_campaign/_cover.jpg",
    name: "YOOX Net-A-Porter Fall 24 Campaign",
    field: "Fashion",
    services: "Art Direction & Film Direction",
    images: [
      "/projects/yoox-net-a-porter_fall_24_campaign/2.jpg",
      "/projects/yoox-net-a-porter_fall_24_campaign/3.jpg",
      "https://player.vimeo.com/video/1007834955?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479___https://player.vimeo.com/video/1007597493?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ],
    credits: "Photography by Ben Parks",
  },
  {
    url: "/projects/rebelqueen_fw19-20_editorial",
    image: "/projects/rebelqueen_fw19-20_editorial/_cover_mobile.jpg",
    desktop: "/projects/rebelqueen_fw19-20_editorial/_cover.jpg",
    name: "Rebelqueen FW19-20 Editorial",
    field: "Fashion",
    services: "Art Direction",
    images: [
      "/projects/rebelqueen_fw19-20_editorial/2.jpg",
      "/projects/rebelqueen_fw19-20_editorial/3.jpg",
      "/projects/rebelqueen_fw19-20_editorial/4.jpg",
      "/projects/rebelqueen_fw19-20_editorial/5.jpg",
      "/projects/rebelqueen_fw19-20_editorial/6.jpg",
      "/projects/rebelqueen_fw19-20_editorial/7.jpg",
      "/projects/rebelqueen_fw19-20_editorial/8.jpg",
    ],
    credits: "Photography Federico Sorrentino <br> Creative collaboration with Francesco Roncaglia",
  },
];
