import React from "react";
import Text from "./Ui/Text";
import Flex from "./Ui/Flex";

const Services = ({ desktop }) => {
  if (desktop) {
    return (
      <Flex between centerItems custom="max-width: 730px; margin: 0 auto;">
        <Flex col custom="display: grid; grid-template-rows: 1fr 1f 1fr;">
          <Text bold custom="text-align: left;">
            BRAND STRATEGY
          </Text>
          <Text bold custom="text-align: left;">
            SOCIAL DESIGN STRATEGY
          </Text>
          <Text bold custom="text-align: left;">
            EMAIL DESIGN STRATEGY
          </Text>
        </Flex>
        <Flex col centerItems>
          <Text bold custom="text-align: center;">
            CREATIVE & ART DIRECTION
          </Text>
          <Text bold custom="text-align: center;">
            CONTENTS CREATION
          </Text>
          <Text bold custom="text-align: center;">
            FILM WRITING & PRODUCTION
          </Text>
        </Flex>
        <Flex col endItems>
          <Text bold custom="text-align: right;">
            BRAND DESIGN
          </Text>
          <Text bold custom="text-align: right;">
            WEBSITE & E-COMMERCE
          </Text>
          <Text bold custom="text-align: right;">
            PACKAGING & EDITORIAL DESIGN
          </Text>
        </Flex>
      </Flex>
    );
  }
  return (
    <div>
      <Text bold>
        CREATIVE & ART DIRECTION / CONTENTS CREATION / FILM DIRECTION / BRAND
        STRATEGY / BRAND DESIGN / WEBSITE / PACKAGING / EDITORIAL DESIGN
      </Text>
    </div>
  );
};

export default Services;
