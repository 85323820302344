import React from "react";
import Flex from "./Ui/Flex";
import { Link } from "react-router-dom";
import { Desktop } from "./MainContent";
import Text from "./Ui/Text";

const links = [
  { to: "/", text: "PROJECTS" },
  { to: "/services", text: "SERVICES" },
  { to: "/about", text: "ABOUT" },
  { to: "/contacts", text: "CONTACTS" },
];

const Nav = ({ desktop, path, hover, clear }) => {
  const content = (
    <Flex fullW between custom="z-index:1; ">
      {links.map(({ to, text }) => (
        <Link key={to} to={to} onClick={clear}>
          <Flex custom="position: relative;">
            {`/${path}` === to ? (
              <Flex custom="position: absolute; left: -7px; top: -1px;">
                <Text custom={hover ? "color: white;" : ""}>*</Text>
              </Flex>
            ) : null}
            <Text bold custom={hover ? "color: white;" : ""}>
              {text}
            </Text>
          </Flex>
        </Link>
      ))}
    </Flex>
  );

  if (desktop) {
    return (
      <Desktop>
        <Flex custom="padding: 20px">{content}</Flex>
      </Desktop>
    );
  }

  return content;
};

export default Nav;
