import React, { useCallback } from "react";
import Flex from "./Ui/Flex";
import Text from "./Ui/Text";
import { projects } from "./projects";
import styled from "styled-components";
import Media from "./Styles/Media";
import { useNavigate } from "react-router-dom";

const ProjectColumnList = ({ setBackground, setHover, hover }) => {
  const onHover = useCallback(
    (image) => {
      setHover(true);
      setBackground({ image });
    },
    [setHover, setBackground]
  );

  const navigate = useNavigate();
  const onClick = useCallback(
    (url) => {
      navigate(url);
    },
    [navigate]
  );

  // const onLeave = () => {
  //   setHover(false);
  //   setBackground({ color: "white" });
  // };

  return (
    <Box>
      <Flex fullWidth>
        <TitleBox hover={hover}>
          <TextTitle hover={hover} bold>
            Projects
          </TextTitle>
        </TitleBox>
        <Flex col>
          {projects.map(({ name, desktop, url }) => (
            <BoxValue
              onClick={() => onClick(url)}
              onMouseEnter={() => onHover(desktop)}
            >
              <TextArrow hover={hover}>{name}</TextArrow>
            </BoxValue>
          ))}
        </Flex>
      </Flex>
      <BoxMiddle fullWidth>
        <TitleBox hover={hover}>
          <TextTitle hover={hover} bold>
            Role
          </TextTitle>
        </TitleBox>
        <Flex col>
          {projects.map(({ services, desktop, url }) => (
            <BoxValue
              onClick={() => onClick(url)}
              onMouseEnter={() => onHover(desktop)}
            >
              <TextArrow hover={hover}>{services}</TextArrow>
            </BoxValue>
          ))}
        </Flex>
      </BoxMiddle>
      <OnlyDesktop fullWidth>
        <TitleBox hover={hover}>
          <TextTitle hover={hover} bold>
            Field
          </TextTitle>
        </TitleBox>
        <Flex col>
          {projects.map(({ field, desktop, url }) => (
            <BoxValue
              onClick={() => onClick(url)}
              onMouseEnter={() => onHover(desktop)}
            >
              <TextArrow hover={hover}>{field}</TextArrow>
            </BoxValue>
          ))}
        </Flex>
      </OnlyDesktop>
    </Box>
  );
};

export const TextTitle = styled(Text)`
  color: ${(props) => (props.hover ? "white" : "black")};
`;

export const TextArrow = styled(Text)`
  font-family: Arial Narrow, sans-serif;
  font-weight: 400;
  color: ${(props) => (props.hover ? "white" : "black")};
  &:hover {
    font-style: italic;
  }
`;

const BoxValue = styled(Flex)`
  margin-bottom: 2px;
  &:hover {
    cursor: pointer;
  }
`;

const BoxMiddle = styled(Flex)`
  ${Media.tablet`
    justify-content: flex-end;
  `}
  ${Media.desktop`
    justify-content: center;
  `}
`;

const Box = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  max-width: 2000px;
  ${Media.desktop`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const OnlyDesktop = styled(Flex)`
  display: none;
  ${Media.desktop`
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
  `}
`;

const TitleBox = styled.div`
  margin-right: 20px;
  color: ${(props) => (props.hover ? "white" : "black")};
  ${Media.desktop`
    margin-right:60px;
  `}
  ${Media.extraLarge`
    margin-right:120px;
  `}
`;

export default ProjectColumnList;
